import {ReactNode} from 'react'
import {StoresLocation} from '~/stores/context'
import {linkedItems} from '../../util'
import {buildImage, buildUrl} from './util'
import {KenticoLoanOptionsData} from './data'

interface SetStateOptions {
  states: string[]
  setStateBase(value: string | undefined): void
}

interface CreateItemsOptions {
  elements: KenticoLoanOptionsData['elements']
  state: string | undefined
}

interface OnChangeOptions {
  onChange(value: string): void
}

interface Event {
  target: {
    value: string
  }
}

interface CreateEffectOptions {
  location: StoresLocation
  setState(value: string): void
}

/** Loan options item. */
export interface LoanOptionsItem {
  amount: number
  description: string
  image?: ReactNode
  title: string
  approve: {
    text: string
    url?: string
  }
  learnMore: {
    text: string
    url?: string
  }
}

/**
 * Create set state handler.
 * @return Set state handler
 */
export const createSetStateHandler = ({
  setStateBase,
  states,
}: SetStateOptions) => (state: string) => {
  if (states.includes(state)) {
    setStateBase(state)
  } else {
    setStateBase(undefined)
  }
}

/**
 * Create loan options items.
 * @return Loan options items
 */
export const createItems = ({
  elements,
  state,
}: CreateItemsOptions): LoanOptionsItem[] => {
  const stateAmounts = linkedItems(elements.items).find(
    i => i.elements.state.value === state,
  )
  const amounts = stateAmounts ? stateAmounts.elements : elements
  const data = [
    {
      amount: amounts.amount1,
      approveLink: elements.approveButtonLink1,
      approveText: elements.approveButtonText1,
      description: elements.description1,
      image: elements.image1,
      learnLink: elements.learnMoreButtonLink1,
      learnText: elements.learnMoreButtonText1,
      title: elements.title1,
    },
  ]

  return data
    .filter(({amount}) => amount.value != undefined)
    .filter(({approveLink}) => linkedItems(approveLink).length > 0)
    .filter(({learnLink}) => linkedItems(learnLink).length > 0)
    .map(
      ({
        amount,
        approveLink,
        approveText,
        description,
        image,
        learnLink,
        learnText,
        title,
      }) => ({
        amount: Number(amount.value),
        approve: {
          text: approveText.value,
          url: buildUrl(linkedItems(approveLink)),
        },
        description: description.value,
        image: buildImage(image.value),
        learnMore: {
          text: learnText.value,
          url: buildUrl(linkedItems(learnLink)),
        },
        title: title.value,
      }),
    )
}

/**
 * Create on change handler.
 * @return On change handler
 */
export const createOnChange = ({onChange}: OnChangeOptions) => ({
  target,
}: Event) => {
  onChange(target.value)
}

/**
 * Create effect.
 * @return Effect
 */
export const createEffect = ({
  location,
  setState,
}: CreateEffectOptions) => () => {
  if (location.ready) {
    setState(location.state)
  }
}
