import {darken} from 'polished'
import React, {ReactNode} from 'react'
import styled from 'styled-components'
import {Col, Row} from '~/bootstrap/components/layout'
import {HeaderDropdown as BaseHeaderDropdown} from './dropdown'

interface Props {
  dropdownLinks: ReactNode
}

const HeaderDropdown = styled(BaseHeaderDropdown)`
  && {
    padding: 0;
  }

  > .dropdown-menu {
    width: 100vw;
    max-width: 360px;
  }
`

const LoanLinks = styled(Col)`
  border-right: 1px solid ${({theme}) => darken(0.2, theme.colors.light)};

  && {
    padding: 0;
  }
`

/**
 * Render loan type dropdown from Kentico data.
 * @return React component
 */
export const LoanType = ({
  dropdownLinks,
}: Props) => (
  <HeaderDropdown title="Loan Types">
    <Row>
      <LoanLinks>{dropdownLinks}</LoanLinks>
    </Row>
  </HeaderDropdown>
)
