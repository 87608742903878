import {darken} from 'polished'
import React, {ReactNode, useCallback} from 'react'
import styled from 'styled-components'
import {Button} from '~/bootstrap/components/button'
import {Form as BootstrapForm, Input, Label} from '~/bootstrap/components/form'
import {
  Col,
  Container as BootstrapContainer,
  Row as BootstrapRow,
} from '~/bootstrap/components/layout'
import {Link} from '~/common/components/link'
import {CURRENCIES} from '~/common/util'
import {LoanOptionsItem, createOnChange} from './factories'

interface Props {
  items: LoanOptionsItem[];
  header: LoanOptionsHeader;
  dynamic: boolean;
  states: string[];
  state?: string;
  editLink?: ReactNode;
  onChange(state: string): void;
}

/** Loan options header. */
export interface LoanOptionsHeader {
  title: string;
  notFound: string;
}

/** Loan options footer. */
export interface LoanOptionsFooter {
  title: string;
  button: {
    text: string;
    url?: string;
  };
}

const STATES: {[key: string]: string} = {
  ID: 'Idaho',
  IL: 'Illinois',
  MI: 'Michigan',
  NM: 'New Mexico',
  TX: 'Texas',
  UT: 'Utah',
  WI: 'Wisconsin',
}

const Container = styled(BootstrapContainer)`
  padding-top: ${({theme}) => theme.spacers[5]};
  padding-bottom: ${({theme}) => theme.spacers[5]};
`

const ImageContainer = styled.div`
  padding-bottom: ${({theme}) => theme.spacers[3]};
`

const BodyCol = styled(Col)`
  margin: ${({theme}) => theme.spacers[3]} 0;
`

const Row = styled(BootstrapRow)`
  padding: ${({theme}) => theme.spacers[4]} 0;
`

const BodyRow = styled(Row)`
  text-align: center;
  justify-content: center;
`

const Frame = styled.div`
  border: 1px solid ${({theme}) => darken(0.2, theme.colors.light)};
  border-radius: 0.5rem;
  padding: ${({theme}) => theme.spacers[4]};
`

const Form = styled(BootstrapForm)`
  && {
    display: inline-flex;
    justify-content: center;
  }

  > *:not(:first-child) {
    margin-left: ${({theme}) => theme.spacers[1]};
  }
`

const HeaderCol = styled(Col)`
  @media (max-width: ${({theme}) => theme.breakpoints.max.lg}) {
    text-align: center;
  }
`

const LocationCol = styled(Col)`
  text-align: right;

  @media (max-width: ${({theme}) => theme.breakpoints.max.lg}) {
    text-align: center;
  }
`

const ColTitle = styled.h4`
  font-weight: normal;
`

const ErrorMessage = styled(Row)`
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding-top: ${({theme}) => theme.spacers[5]};
  padding-right: ${({theme}) => theme.spacers[3]};
  padding-left: ${({theme}) => theme.spacers[3]};
  align-items: center;
  color: ${({theme}) => theme.colors.red};
`

/**
 * Render loan options.
 * @return React component
 */
export const LoanOptions = ({
  dynamic,
  editLink,
  header,
  items,
  onChange,
  state,
  states,
}: Props) => {
  const onSelectChange = useCallback(
    createOnChange({
      onChange,
    }),
    [onChange],
  )

  let stateOptions
  if (dynamic) {
    stateOptions = states.map(element => (
      <option key={element} value={element}>
        {STATES[element]}
      </option>
    ))
  }

  const label =
    state === undefined ? header.notFound : 'Update Your Location: '

  const columns = items.map(
    ({amount, title, description, image, approve, learnMore}, index) => (
      // No better key
      // eslint-disable-next-line react/no-array-index-key
      <BodyCol key={index} lg="4" md="8">
        <Frame>
          <ImageContainer>{image}</ImageContainer>
          <ColTitle>{title}</ColTitle>
          <h3>Up to {CURRENCIES.USD.format(amount)}</h3>
          <p>{description}</p>
          <Button color="tertiary" tag={Link} to={approve.url}>
            {approve.text}
          </Button>
          <div>
            <Button color="link" tag={Link} to={learnMore.url}>
              {learnMore.text}
            </Button>
          </div>
        </Frame>
      </BodyCol>
    ),
  )
  let headerElement = (
    <Row>
      <Col align="center">
        <h2>{header.title}</h2>
      </Col>
    </Row>
  )
  if (dynamic) {
    headerElement = (
      <>
        <Row>
          <HeaderCol lg="5">
            <h2>{header.title}</h2>
          </HeaderCol>
          <LocationCol lg>
            <Form inline>
              <Label>{label}</Label>
              <Input
                aria-label="Select State"
                name="state"
                type="select"
                value={state}
                onChange={onSelectChange}
              >
                <option disabled value="">
                  Select State
                </option>
                {stateOptions}
              </Input>
            </Form>
          </LocationCol>
        </Row>
        {state == 'IL' && (
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          <ErrorMessage>
            Illinois customers may take out a loan at any of our bordering
            Wisconsin stores.
          </ErrorMessage>
        )}
      </>
    )
  }
  return (
    <Container>
      {editLink}
      {headerElement}
      <BodyRow>{columns}</BodyRow>
    </Container>
  )
}
